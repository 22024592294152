<template>
  <v-container class="px-0 py-0 report-pdf" fluid>
    <v-overlay opacity="0" :value="true" z-index="999">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
      ></v-progress-circular>
    </v-overlay>
    <!-- report content -->
    <v-container
      class="py-9 px-10"
      fluid
      v-if="!isPublic || (isPublic && report.id)"
    >
      <!-- report name and actions -->
      <v-row justify="space-between">
        <v-col cols="12" md="4">
          <h2>
            <editable-label
              label="Reports /"
              :disabled="isLocked"
              :text.sync="report.name"
            />
          </h2>
        </v-col>
      </v-row>

      <v-row class="utility-bar pt-2 pb-3 pb-md-2 pl-1">
        <v-col class="py-0 font-weight-light caption">
          <b>Report created:&nbsp;</b>
          <span>{{ createdDate }}</span>
        </v-col>
      </v-row>

      <template>
        <!-- header -->
        <template v-if="report.report_header_template">
          <report-header
            :item="reportHeader"
            :reportId="report.id"
            :disabled="true"
          />
        </template>

        <!-- Customer Name & job name & address -->
        <v-row class="px-1">
          <v-col cols="12" md="4" class="px-0">
            <v-text-field
              hide-details
              dense
              outlined
              label="Customer Name"
              :disabled="isLocked"
              v-model="project.customer_name"
              class="pa-2"
              color="accent"
            />
          </v-col>
          <v-col cols="12" md="4" class="px-0">
            <v-text-field
              hide-details
              dense
              outlined
              label="Job Code"
              :disabled="isLocked"
              v-model="project.job_code"
              class="pa-2"
              color="accent"
            />
          </v-col>
          <v-col cols="12" md="4" class="px-0">
            <v-text-field
              hide-details
              dense
              outlined
              label="Location"
              :disabled="isLocked"
              v-model="project.address"
              class="pa-2"
              color="accent"
            />
          </v-col>
        </v-row>

        <!-- file size, last updated, status-->
        <!-- <v-row class="mt-2">
          <v-col
            v-for="detail in projectDetails"
            :key="detail.label"
            cols="auto"
            class="font-weight-light caption"
          >
            <v-icon size="14px">{{ detail.icon }}</v-icon>
            {{ detail.label }}: {{ detail.text }}
          </v-col>
        </v-row> -->

        <!-- Notes -->
        <v-row class="pt-1">
          <v-col>
            <project-notes
              :project="project"
              :isLocked="true"
              v-if="project.id"
            />
          </v-col>
        </v-row>

        <div
          v-for="selectedAreaOfInterest in areasOfInterest"
          :key="selectedAreaOfInterest.id"
        >
          <!-- area of interest -->
          <v-row class="pt-8" justify="space-between">
            <v-col cols="auto" class="d-flex">
              <editable-label
                label="Area of Interest:"
                labelClass="font-weight-medium"
                :disabled="isLocked"
                :text.sync="selectedAreaOfInterest.name"
              />
            </v-col>
          </v-row>

          <!-- scans; attachments; notes -->
          <v-row>
            <v-container class="area-of-interest-container mx-3" fluid>
              <report-attachments
                ref="attachments"
                :project="project"
                :selectAOI="selectedAreaOfInterest"
                v-if="report.sites_data_allowed"
                :isPdfView="true"
              />
              <project-area-of-interest-notes
                :isLocked="isLocked"
                :areaOfInterest="selectedAreaOfInterest"
              />
              <project-scans
                ref="scans"
                :project="project"
                :areaOfInterest="selectedAreaOfInterest"
                v-if="report.gpr_data_allowed"
              />
            </v-container>
          </v-row>
        </div>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import { HeaderKeys, projects, setHeaders } from "@/api";
import Vue from "vue";
import EditableLabel from "@/components/common/EditableLabel.vue";
import ProjectNotes from "@/components/project/ProjectNotes.vue";
import ProjectScans from "@/components/project/scans/ProjectScans.vue";
import ProjectAreaOfInterestNotes from "@/components/project/ProjectAreaOfInterestNotes.vue";
import ReportHeader from "@/components/reports/report/reportContent/ReportHeader.vue";
import { getDate, sizeInMB } from "@/utils";
import ReportAttachments from "@/components/reports/report/reportContent/reportAttachments/ReportAttachments.vue";
import html2pdf from "html2pdf.js";
import { attachments } from "@/api";
import { Actions, Mutations } from "@/store/models";
import { BroadcaseMessage, Broadcast } from "@/utils/broadcast";

export default Vue.extend({
  name: "ReportPdf",
  components: {
    EditableLabel,
    ProjectNotes,
    ProjectScans,
    ProjectAreaOfInterestNotes,
    ReportHeader,
    ReportAttachments,
  },
  data() {
    return {
      report: {},
      project: {},
      areasOfInterest: [],
      reportHeader: {},
    };
  },
  computed: {
    id: function () {
      return Number(this.$route.params.id);
    },
    isLocked: function () {
      return true;
    },
    projectDetails: function () {
      return [
        // {
        //   label: "Project Address",
        //   text: this.project.address,
        //   icon: "mdi-map-marker",
        // },
        {
          label: "File Size",
          text: sizeInMB(this.project.size),
          icon: "mdi-database",
        },
        {
          label: "Last Updated",
          text: getDate(
            this.project.updated_datetime || this.project.created_datetime
          ),
          icon: "mdi-calendar-month",
        },
      ];
    },
    isPublic: function () {
      return !this.id;
    },
    createdDate: function () {
      return getDate(this.report?.created_datetime);
    },
    name: function () {
      return this.$route.query.name;
    },
  },
  async created() {
    // download only
    if (this.isPublic) {
      this.handleDownload();
      // save / upload to server
    } else {
      this.handleSave();
    }
  },
  methods: {
    async getReport() {
      try {
        this.report = (await projects.reports.get(this.id)).data[0];
        this.project = { ...this.report.project, status: 0 }; // defaulting project to locked state
        await this.getAreasOfInterest();
        await this.getReportHeader();
      } catch (e) {
        this.close();
      }
    },
    async getAreasOfInterest() {
      const r = (await projects.areaOfInterests.getAll(this.project.id)).data;
      const reportAOI = new Set(
        this.report.project_reports_areas_of_interests.map(
          (a) => a.projects_areas_of_interest.id
        )
      );

      this.areasOfInterest = r.filter((a) => reportAOI.has(a.id));
    },
    async getReportHeader() {
      this.reportHeader = {
        ...this.report.report_header_template,
        ...this.report.report_header_json,
      };
    },

    // save
    async handleSave() {
      this.$store.commit(Mutations.SetHideNavDrawer, true);
      await this.getReport();
      setTimeout(async () => {
        this.save();
      }, 10 * 1000);
    },
    async save() {
      const element = document.getElementById("app");
      const opt = {
        margin: 0,
        filename: this.report.name,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          useCORS: true,
          y: 64,
          scale: 4,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      try {
        // generate pdf
        const pdfObj = await html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .get("pdf");
        const perBlob = pdfObj.output("blob");
        // upload pdf to server
        await attachments.reports.add(this.report.project.id, {
          data: perBlob,
          name: this.name,
        });
        const bc = Broadcast.getInstance();
        bc.postMessage(BroadcaseMessage.PdfGenerated);
        // download pdf - commented out for now
        // await pdfObj.save();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.close();
      }
    },

    // download
    async handleDownload() {
      const password = window.atob(this.$route.query.download);
      setHeaders([
        {
          key: HeaderKeys.Password,
          value: password,
        },
      ]);
      await this.getReport();
      setTimeout(async () => {
        this.download();
      }, 3 * 1000);
    },
    async download() {
      const element = document.getElementById("app");
      const opt = {
        margin: 0,
        filename: this.report.name,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf(element, opt);
      setTimeout(async () => {
        this.close();
      }, 1 * 1000);
    },

    close() {
      window.close();
    },
  },
});
</script>
<style lang="scss">
.report-pdf {
  background: white !important;
  color: black !important;
  div,
  h2,
  h5,
  span,
  input,
  label,
  hr,
  i,
  p,
  textarea {
    color: black !important;
    border-color: rgb(135, 131, 131) !important;
  }
  .utility-bar {
    background: white !important;
  }
  .v-text-field--outlined fieldset {
    color: rgb(135, 131, 131) !important;
  }
  .v-icon {
    color: rgb(135, 131, 131) !important;
  }

  .area-of-interest-container {
    border: $container-border;
    border-radius: 5px;
    background-color: white;
    margin: 0;
    padding: 10px 20px;
  }
}
</style>